import lastIndexOf from 'lodash/lastIndexOf';
import { init } from 'components/link/builder';

// Identifier for pages in pagination
export const PAGE_ID = 'page-';

const linksBildControl = {
  project: 'bildcontrol',
  // Home
  sectionBildControl: props => ({
    href: `/${props.langPrefix.replace('/', '')}`,
    title: props.title || props.name
  }),
  // about
  sectionAbout: props => ({
    href: `/${props.langPrefix}about`
  }),
  // Landing page
  sectionLanding: props => ({
    href: addPageSlash(props.url, props.page),
    query: props.query,
    title: props.title || props.name
  }),
  sectionHowWeCalculateScore: props => ({
    href: `/${props.langPrefix}how-we-calculate-score`
  }),
  sectionContacts: props => ({
    href: `/${props.langPrefix}contacts`
  }),
  /*
  sectionSubscribe: () => ({
    href: '/subscribe',
    title: 'О подписке'
  }),
  */
  // terms
  sectionTerms: props => ({
    href: `/${props.langPrefix}soglashenie`,
    title: props.title || props.name
  }),
  // registrationCondition
  sectionRegistrationCondition: props => ({
    href: `/${props.langPrefix}confidence`,
    title: props.title || props.name
  }),
  // Favorites
  pageFavorites: props => ({
    href: `/${props.langPrefix}auth/favorites/${props.project}`,
    title: props.name || props.title
  }),
};

function addPageSlash(url = '', page) {
  if (page) {
    return `${url}${lastIndexOf(url, '/') === url.length - 1 ? '' : '/'}${PAGE_ID}${page}`;
  }
  return url;
}

export default init(linksBildControl);
